import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import React from "react"
import Header from "./Header"

const TopNav = () => {
  const [showMenu, setShowMenu] = React.useState(false)
  const showHideMenu = () => {
    setShowMenu(!showMenu)
  }

  return (
    <div>
      <div id="top_nav" className={showMenu ? "open" : ""}>
        <div id="full_navigation" className={showMenu ? "open" : ""}>
          <ul id="menu_one">
            <li>
              Coverage Types &nbsp;
              <FontAwesomeIcon icon={faCaretDown} size="sm" />
            </li>
            <li>
              <Link to="/business-owners-policy">Business Owner's Policy</Link>
            </li>
            <li>
              <Link to="/professional-liability-insurance">
                Professional Liability
              </Link>
            </li>
            <li>
              <Link to="/commercial-auto-insurance">Commercial Auto</Link>
            </li>
            <li>
              <Link to="/errors-and-omissions-insurance">
                Errors &amp; Omissions
              </Link>
            </li>
            <li>
              <Link to="/workers-compensation-insurance">
                Worker's Compensation
              </Link>
            </li>
            <li>
              <Link to="/premises-liability-insurance">Premises Liability</Link>
            </li>
            <li>
              <Link to="/builders-risk-insurance">Builder's Risk</Link>
            </li>
            <li>
              <Link to="/commercial-fleet-insurance">Fleet Insurance</Link>
            </li>
            <li>
              <Link to="/all-coverage-types">More...</Link>
            </li>
          </ul>
          <ul id="menu_two">
            <li>
              Coverage by Industry &nbsp;
              <FontAwesomeIcon icon={faCaretDown} size="sm" />
            </li>
            <li>
              <Link to="/building-contractor-insurance">
                Building Contractors
              </Link>
            </li>
            <li>
              <Link to="/commercial-trucking-insurance">
                Trucking Companies
              </Link>
            </li>
            <li>
              <Link to="/manufacturing-insurance">Manufacturers</Link>
            </li>
            <li>
              <Link to="/garage-insurance">Auto Garages</Link>
            </li>
            <li>
              <Link to="/restaurant-and-bar-insurance">
                Food Service &amp; Dining
              </Link>
            </li>
            <li>
              <Link to="/plumbing-and-hvac-contractors-insurance">
                Plumbing &amp; HVAC
              </Link>
            </li>
            <li>
              <Link to="/insurance-for-accountants-and-bookkeepers">
                Accounting &amp; Bookkeeping
              </Link>
            </li>
            <li>
              <Link to="/business-insurance-for-all-industries">More...</Link>
            </li>
          </ul>
          <div id="header_quote">
            <Link to="/">
              <button className="request-quote">Request a Quote</button>
            </Link>
          </div>
        </div>
      </div>
      <div
        id="nav_icon"
        className={showMenu ? "open" : ""}
        onClick={showHideMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default TopNav
