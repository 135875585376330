import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from "gatsby"
import logo from '../img/logo-word-mark.svg'
import '../styles/nav.css'
import Session321 from './Session321';
import { useLayoutEffect } from "react";

const Header = (props) => {
    useLayoutEffect(() => {
        var callback = function(formatted_number, mobile_number) {
            var e = document.getElementById("number");
            e.innerHTML = "";
            e.appendChild(document.createTextNode(formatted_number));
          };
          
          window.gtag('config', '948606774/85IeCL31q6IDELauqsQD', {
            'phone_conversion_number': '234-230-6321',
            'phone_conversion_css_class':'call-conversion',
            'phone_conversion_callback': callback
          });
      });

    return ( 
    <div id="header">
        <Helmet><meta name="facebook-domain-verification" content="ko98e5hu5grsna5ge6dzc37n29u2w7" /></Helmet>
        <Link to="/"><div id="logo"><img src={logo} alt="menu icon" height="70px" width="240px"/></div></Link>
        <Session321 />
    </div> );
}
 
export default Header;