import React from 'react';
import { Link } from "gatsby"
import logo from '../img/wordmark-white.svg'
import '../styles/nav.css'
import Session321 from './Session321';



const HeaderDark = (props) => {
    return (
    <div id="header_dark">
        <Link to="/"><div id="logo"><img src={logo} alt="Insurance321 White Logo"/></div></Link>
        <Session321 />
    </div> );
}

export default HeaderDark;
